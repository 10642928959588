import { useState, Fragment } from 'react'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import ModalExitButton from './ModalExitButton'
import TextGiftCodeModal from './TextGiftCodeModal'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import './Modal.css'

type Props = {
    modalOpen: boolean
    setModalOpen: Function
    giftCodeStatus: string
    giftCode?: string
    email: string
    resetState: Function
}

const GiftCodeStatusModal:React.FC<Props> = ({
    modalOpen,
    setModalOpen,
    giftCodeStatus,
    giftCode,
    resetState,
    email
}) => {
    const [textGiftCodeModalOpen, setTextGiftCodeModalOpen] = useState<boolean>(false)

    const handleClose = () => {
        resetState()
        setModalOpen(false)
    }
    
    return (
        <Fragment>
            <Dialog
                fullWidth={true}
                maxWidth='lg'
                open={modalOpen}
                onClose={() => {}}
            >
                <DialogContent>
                    <Grid container spacing={1} sx={{height: '85vh', paddingX: '0px'}}>
                        <Grid item xs={12} sx={{textAlign: 'center', paddingBottom: '30px'}}>
                            {
                                giftCodeStatus === "PENDING" ?
                                    <Fragment>
                                        <Typography variant="h4" gutterBottom color={"#193959"} sx={{marginBottom: "25px", textAlign: 'center', marginTop: '2vh'}}>
                                            Processing gift order...
                                        </Typography>
                                        <img src={require('../media/GiftSquirrel3.png')} width='200px' alt='Squirrel under an oak tree'/>
                                    </Fragment>
                                : giftCode && giftCodeStatus === "SUCCESS" ? 
                                    <Fragment>
                                        <Typography variant="h4" gutterBottom color={"#193959"} sx={{marginBottom: "25px", textAlign: 'center', marginTop: '2vh'}}>
                                            Success!
                                        </Typography>
                                        <Typography variant="body1" gutterBottom sx={{textAlign: 'center'}}>
                                            Share the code below with your gift recipient. This code will also be emailed to {email}.
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                marginTop: '20px',
                                                marginBottom: '65px',
                                                padding: '8px',
                                                width: 'fit-content',
                                                borderStyle: 'solid',
                                                borderWidth: '2px',
                                                marginX: 'auto'
                                            }}
                                            >
                                            {giftCode}
                                        </Typography>
                                        <img src={require('../media/GiftSquirrel3.png')} width='190px' alt='Squirrel under an oak tree giving an acorn to his squirrel friend'/>
                                        <Typography variant="body1" gutterBottom sx={{textAlign: 'center', marginTop: '45px'}}>
                                            <img src={require('../media/Acorn.png')} width='20px' alt='Acorn icon' style={{marginRight: '5px'}} />
                                            Please ensure that the email was received before exiting this window.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom sx={{textAlign: 'center', marginBottom: '15px'}}>
                                            You can also copy and save the code above.
                                        </Typography>
                                        <Stack direction="row" spacing={5} sx={{justifyContent: 'center'}}>
                                            <Button variant="contained" onClick={() => handleClose()}>
                                                I have my code, exit
                                            </Button>
                                            {/* <Button variant="contained" onClick={() => setTextGiftCodeModalOpen(true)}>
                                                Text me the code
                                            </Button> */}
                                        </Stack>
                                    </Fragment>
                                : giftCodeStatus === "ERROR" ? 
                                    <Fragment>
                                        <ModalExitButton onClickCallback={handleClose} />
                                        <Typography variant="h4" gutterBottom color={"#193959"} sx={{marginBottom: "15px", textAlign: 'center', marginTop: '2vh'}}>
                                            Oh no! We're sorry!
                                        </Typography>
                                        <Typography variant="body1" gutterBottom sx={{textAlign: 'center'}}>
                                            There was a problem creating your gift code. You will not be charged.
                                        </Typography>
                                        <img src={require('../media/GiftSquirrel2.png')} width='200px' alt='Squirrel under an oak tree'/>
                                    </Fragment>
                                :
                                null
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <TextGiftCodeModal
                modalOpen={textGiftCodeModalOpen}
                setModalOpen={setTextGiftCodeModalOpen}
                giftCode={giftCode!!}
            />
        </Fragment>
    )
}

export default GiftCodeStatusModal
