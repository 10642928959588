import { useState } from 'react'

import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'

import ModalExitButton from './ModalExitButton'

import { sendGiftCodeBalanced } from '../clients/TransmissionClient'
import { generateSourceId } from '../utils/Helpers'
import { SendGiftCode } from '../types/SendGiftCode'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import './Modal.css'

type Props = {
    modalOpen: boolean
    setModalOpen: Function
    giftCode: string
}

const EnterGiftCodeModal:React.FC<Props> = ({
    modalOpen,
    setModalOpen,
    giftCode
}) => {
    const [phone, setPhone] = useState<string>('')
    const [validPhone, setValidPhone] = useState<boolean>(false)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [disableSubmit, setDisableSubmit] = useState<boolean>(false)

    const handleClose = () => {
        setPhone('')
        setValidPhone(false)
        setShowAlert(false)
        setShowSuccess(false)
        setDisableSubmit(false)
        setModalOpen(false)
    }

    const updatePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
        const filteredPhone = event.target.value.replace(/\D/g,'')

        setPhone(filteredPhone)
        setValidPhone(filteredPhone.length === 10)
    }

    const handleSubmit = () => {
        setDisableSubmit(true)

        const data: SendGiftCode = {
            phone: phone,
            giftCode: giftCode,
            sourceId: generateSourceId()
        }

        sendGiftCodeBalanced(data).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setShowSuccess(true)
            } else {
                setShowAlert(true)
                setTimeout(() => {
                    setDisableSubmit(false)
                }, 2000)
            }
        })
    }
    
    return (
        <Dialog
            fullWidth={true}
            maxWidth='sm'
            open={modalOpen}
            onClose={handleClose}
        >
            <DialogContent>
                <Grid container spacing={1} sx={{height: '220px', paddingX: '0px'}}>
                    <Grid item xs={12} md={12}>
                        <ModalExitButton onClickCallback={handleClose} />
                        <TextField
                            fullWidth
                            helperText={validPhone ? null : "*Please enter a valid 10-digit phone number"}
                            margin="dense"
                            id="iris-gift-code-phone"
                            label="Mobile Phone"
                            rows={1}
                            value={phone}
                            onChange={updatePhone}
                            sx={{marginBottom: "15px", marginTop: '15px'}}
                        />
                        {showAlert ? <Alert severity="error" sx={{marginBottom: '10px'}}>Unable to send gift code, or this code was already sent.</Alert> : null}
                        {showSuccess ? <Alert severity="success" sx={{marginBottom: '10px'}}>Text message sent.</Alert> : null}
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={!validPhone || disableSubmit}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default EnterGiftCodeModal
