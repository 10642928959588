import { useState, useEffect, Fragment } from 'react'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import ModalExitButton from './ModalExitButton'

import SquirrelRocket from '../media/SquirrelRocket.mp4'

import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    RedditShareButton,
    RedditIcon,
    TwitterShareButton,
    TwitterIcon
  } from "react-share"

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import './Modal.css'

type Props = {
    modalOpen: boolean
    setModalOpen: Function
    successMessage?: string
    orderId?: string
    clearHomepage: Function
}

const TransmissionModal:React.FC<Props> = ({
    modalOpen,
    setModalOpen,
    successMessage,
    orderId,
    clearHomepage
}) => {
    const [timeLeft, setTimeLeft] = useState<number>(64)
    const [intervalId, setIntervalId] = useState<any>(null)
    const [animationPlayed, setAnimationPlayed] = useState<boolean>(false)

    const SHARE_URL = 'https://irismessage.org'
    const SHARE_MESSAGE = 'I just sent a messages to the universe that lasts forever!'
    const SHARE_SUBJECT = 'Send a message to the universe'

    const handleClose = () => {
        clearInterval(intervalId)
        clearHomepage()
        setModalOpen(false)
        setTimeLeft(64)
        setAnimationPlayed(false)
    }

    useEffect(() => {
        if(timeLeft === 0) {
            handleClose()
        }
    }, [timeLeft])

    useEffect(() => {
        if(modalOpen) {
            const id = setInterval(() => {
                setTimeLeft((currentTime) => currentTime - 1)
            }, 1000)
            setIntervalId(id)

            setTimeout(() => {
                setAnimationPlayed(true)
            }, 3500)
        }
    }, [modalOpen])
    
    return (
        <Dialog
            fullWidth={true}
            maxWidth='lg'
            open={modalOpen}
            onClose={animationPlayed ? handleClose : () => {}}
        >
            <DialogContent>
                <Grid container spacing={1} sx={{height: '85vh', paddingX: '0px'}}>
                    <Grid item xs={12} md={12}>
                        {!animationPlayed ?
                            <video autoPlay muted width='95%'>
                                <source src={SquirrelRocket} type='video/mp4' />
                            </video>
                            :
                        <Fragment>
                            <ModalExitButton onClickCallback={handleClose} />
                            <Typography variant="h4" gutterBottom color={"#193959"} sx={{marginBottom: "5px", textAlign: 'center'}}>
                                Thank you for using irismessage.org!
                            </Typography>
                            {orderId ?
                            <Typography variant="subtitle1" gutterBottom color={"#193959"} sx={{marginBottom: "10px", textAlign: 'center'}}>
                                Your confirmation number is <b>{orderId}</b>
                            </Typography> :
                            null}
                            <Typography variant="body1" gutterBottom sx={{textAlign: 'center'}}>
                                You're done! Your message is being sent to the universe. This window will close in {timeLeft} seconds.
                            </Typography>
                            {successMessage ?
                            <Typography variant="body1" gutterBottom sx={{textAlign: 'center'}}>
                                <b>{successMessage}</b>
                            </Typography> :
                            null}
                            <Typography variant="body1" gutterBottom sx={{textAlign: 'center', marginTop: '5px'}}>
                                <img src={require('../media/Acorn.png')} width='20px' alt='Acorn icon' style={{marginRight: '5px'}} />
                                If you enjoyed this process, consider giving Iris messages as a gift: <Link href="/giftamessage">Gift a Message</Link>
                            </Typography>
                            <Stack direction="row" spacing={2} sx={{marginY: '10px', justifyContent: 'center'}}>
                            <FacebookShareButton
                                url={SHARE_URL}
                                quote={SHARE_MESSAGE}
                                >
                                <FacebookIcon size={35} round={true} />
                            </FacebookShareButton>
                            <TwitterShareButton
                                url={SHARE_URL}
                                title={SHARE_MESSAGE}
                            >
                                <TwitterIcon size={35} round={true} />
                            </TwitterShareButton>
                            <RedditShareButton
                                url={SHARE_URL}
                                title={SHARE_MESSAGE}
                            >
                                <RedditIcon size={35} round={true} />
                            </RedditShareButton>
                            <EmailShareButton
                                url={SHARE_URL}
                                subject={SHARE_SUBJECT}
                                body={SHARE_MESSAGE}
                            >
                                <EmailIcon size={35} round={true} />
                            </EmailShareButton>
                            </Stack>
                            <Grid item xs={12} sx={{textAlign: 'center'}}>
                                <img src={require('../media/Transmission.png')} width='85%' alt="Sending message..." />
                            </Grid>
                        </Fragment>
                    }
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default TransmissionModal
