import { Routes, Route } from 'react-router-dom'
import Home from '../pages/Home'
import FAQs from '../pages/FAQs'
import Contact from '../pages/Contact'
import ErrorPage from '../pages/ErrorPage'
import GiftAMessage from '../pages/GiftAMessage'
import StatsMessages from '../pages/StatsMessages'

function IrisRouter() {
    return (
        <Routes>
            <Route path={"/"} element={<Home />}/>
            <Route path={"/about"} element={<FAQs />} />
            <Route path={"/giftamessage"} element={<GiftAMessage />} />
            <Route path={"/contact"} element={<Contact />} />
            <Route path={"/stats/messages"} element={<StatsMessages />} />
            <Route path={"*"} element={<ErrorPage />} />
        </Routes>
    )
}

export default IrisRouter
