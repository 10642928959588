import { useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import MenuButton from '../components/MenuButton'

import './TopNavBar.css'

export default function TopNavBar() {
    const navigate = useNavigate()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} className='NavGrid'>
                <Typography variant="subtitle1" gutterBottom color={"#FFFFFF"} sx={{textAlign: 'center', paddingTop: '4px', paddingX: '5px'}}>
                    20% of profits from your message donated to the charity of your choice
                </Typography>
            </Grid>
            <Grid item xs={1} md={3}>
                <MenuButton />
            </Grid>
            <Grid item xs={10} md={6} sx={{textAlign: "center", marginBottom: '5px', padding: '0px'}}>
                <Button
                    disableRipple
                    sx={{ "&:hover": { backgroundColor: "transparent" }} }
                    onClick={() => {navigate("/")}}
                >
                    <img
                        src={require('../media/IrisBanner.png')}
                        width='225px'
                        alt='Iris message banner'
                    />
                </Button>
            </Grid>
            <Grid item xs={1} md={3}></Grid>
        </Grid>
    )
}