import { Fragment } from "react"
import Typography from '@mui/material/Typography'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

type Props = {
    question: string
    answer: string
}

const FAQ:React.FC<Props> = ({question, answer}) => {
    return (
        <Fragment>
            <Typography variant="h6" gutterBottom color={"#193959"} sx={{textAlign: 'center', marginY: '10px'}}>
                <b>{question}</b>
            </Typography>
            <Typography variant="body1" gutterBottom sx={{marginBottom: '40px'}}>
                {answer}
            </Typography>
        </Fragment>
    )
}

export default FAQ
