import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'

import ModalExitButton from './ModalExitButton'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import './Modal.css'

type Props = {
    modalOpen: boolean
    setModalOpen: Function
    setMessage: Function
    setCharsLeft: Function
    setMessageType: Function
    setCharity: Function
}

const TransmissionErrorModal:React.FC<Props> = ({
    modalOpen,
    setModalOpen,
    setMessage,
    setCharsLeft,
    setMessageType,
    setCharity
}) => {
    const handleClose = () => {
        setMessage('')
        setCharsLeft(350)
        setMessageType('TEXT')
        setCharity('')
        setModalOpen(false)
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth='lg'
            open={modalOpen}
            onClose={handleClose}
        >
            <DialogContent>
                <Grid container spacing={1} sx={{height: '85vh', paddingX: '0px'}}>
                    <Grid item xs={12} md={12}>
                        <ModalExitButton onClickCallback={handleClose} />
                        <Typography variant="h4" gutterBottom color={"#193959"} sx={{marginBottom: "15px", textAlign: 'center', marginTop: '15vh'}}>
                            Oh no! We're sorry!
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{textAlign: 'center'}}>
                            There was a problem sending your message to the radio transmitter. You will not be charged.
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default TransmissionErrorModal