import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { IconProp } from "@fortawesome/fontawesome-svg-core"

import { faCopyright } from "@fortawesome/free-regular-svg-icons"

export default function TopNavBar() {
    const openInsta = () => {
        window.open("https://www.instagram.com/iris_message_official", '_blank', 'noopener,noreferrer')
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sx={{textAlign: 'center'}}>
                <IconButton aria-label="insta" onClick={openInsta}>
                    <FontAwesomeIcon icon={faInstagram as IconProp} style={{color: "#ca028b"}} fontSize="32"/>
                </IconButton>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2" gutterBottom sx={{textAlign: 'center', paddingBottom: '7px'}}>
                    <FontAwesomeIcon icon={faCopyright} fontSize="10"/> Iris Message, LLC
                </Typography>
            </Grid>
        </Grid>
    )
}