import { useState, MouseEvent }from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from "@fortawesome/free-solid-svg-icons"

import './MenuButton.css'

export default function MenuButton() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  
  const handleCloseHome = () => {
    setAnchorEl(null)
    navigate("/")
  }

  const handleCloseAbout = () => {
    setAnchorEl(null)
    navigate("/about")
  }

  const handleCloseGiftMessage = () => {
    setAnchorEl(null)
    navigate("/giftamessage")
  }

  const handleCloseContact = () => {
    setAnchorEl(null)
    navigate("/contact")
  }

  return (
    <div>
      <Button
        id="demo-positioned-button"
        className='IrisMenuButton'
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{marginLeft: '20px', marginTop: '20px', backgroundColor: 'rgb(234, 234, 234)'}}
      >
        <FontAwesomeIcon icon={faBars} fontSize="24"/>
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleCloseHome}>Home</MenuItem>
        <MenuItem onClick={handleCloseGiftMessage}>Gift A Message</MenuItem>
        <MenuItem onClick={handleCloseAbout}>FAQs</MenuItem>
        <MenuItem onClick={handleCloseContact}>Contact Us</MenuItem>
      </Menu>
    </div>
  )
}
