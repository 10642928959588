import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'

import './Modal.css'

type Props = {
    modalOpen: boolean
}

const ConnectingModal:React.FC<Props> = ({
    modalOpen
}) => {
    return (
        <Dialog
            fullWidth={true}
            maxWidth='lg'
            open={modalOpen}
            onClose={() => {}}
        >
            <DialogContent>
                <Grid container spacing={1} sx={{height: '85vh', paddingX: '0px'}}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={11}>
                        <img src={require('../media/Connecting.png')} width='90%' alt='Connecting to radio transmitter'/>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ConnectingModal
