import { useState } from 'react'
import ReCaptchaV2 from 'react-google-recaptcha'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'

import { ContactType } from '../types/ContactType'
import { generateSourceId } from '../utils/Helpers'
import { postContactBalanced } from '../clients/TransmissionClient'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

export default function Contact() {
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [message, setMessage] = useState<string>('')
    const [returnEmail, setReturnEmail] = useState<string>('')
    const [validEmail, setValidEmail] = useState<boolean>(false)
    const [charsLeft, setCharsLeft] = useState<number>(1000)
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [captchaToken, setCaptchaToken] = useState<string | null>(null)

    const handleClear = () => {
        setFirstName('')
        setLastName('')
        setMessage('')
        setReturnEmail('')
        setCharsLeft(1000)
        setSubmitted(false)
        setValidEmail(false)
    }

    const updateMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length <= 1000) {
            setMessage(event.target.value)
            setCharsLeft(1000 - event.target.value.length)
        }
    }

    const updateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length < 250) {
            if(event.target.value.trim().length >= 5 &&
                event.target.value.includes("@") &&
                event.target.value.includes(".") &&
                event.target.value.split(".").length > 1 &&
                event.target.value.split(".")[0] !== '' &&
                event.target.value.split(".")[1] !== ''
            ) {
                setValidEmail(true)
            } else {
                setValidEmail(false)
            }
            setReturnEmail(event.target.value.trim())
        }
    }

    const updateFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length < 100) {
            setFirstName(event.target.value)
        }
    }

    const updateLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length < 100) {
            setLastName(event.target.value)
        }
    }

    const handleTokenChange = (token: string | null) => {
        setCaptchaToken(token)
    }

    const handleTokenExpire = () => {
        setCaptchaToken(null)
    }

    const handleSubmit = () => {
        setSubmitted(true)

        const contactData: ContactType = {
            firstName: firstName,
            lastName: lastName,
            email: returnEmail,
            message: message,
            recaptcha: captchaToken,
            sourceId: generateSourceId()
        }

        postContactBalanced(contactData).then((status) => {
            if (status === 200 || status === 201) {
                setShowAlert(false)
                setShowSuccess(true)

                setTimeout(() => {
                    handleClear()
                }, 5000)
            } else {
                setShowSuccess(false)
                setShowAlert(true)

                setTimeout(() => {
                    setSubmitted(false)
                }, 3000)
            }
        })
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={1} md={2} lg={3} xl={4}></Grid>
            <Grid item xs={10} md={8} lg={6} xl={4}>
                <Typography variant="h3" gutterBottom color={"#193959"} sx={{marginBottom: '20px'}}>
                    Get in touch
                </Typography>
                <Stack direction="row" spacing={1} sx={{marginBottom: '20px'}}>
                    <TextField
                        helperText={!firstName ? "*Please enter your name" : null}
                        required
                        margin="dense"
                        id="iris-message-first-name"
                        label="First Name"
                        rows={1}
                        value={firstName}
                        onChange={updateFirstName}
                        sx={{width: '50%', marginY: '0px'}}
                    />
                    <TextField
                        helperText={!lastName ? "*Please enter your last name" : null}
                        required
                        margin="dense"
                        id="iris-message-last-name"
                        label="Last Name"
                        rows={1}
                        value={lastName}
                        onChange={updateLastName}
                        sx={{width: '50%', marginY: '0px'}}
                    />
                </Stack>
                <TextField
                    helperText={!validEmail ? "*Please enter a valid email" : null}
                    required
                    margin="dense"
                    id="iris-message-email"
                    label="Email"
                    rows={1}
                    value={returnEmail}
                    onChange={updateEmail}
                    sx={{marginBottom: "20px", width: '100%'}}
                />
                <TextField
                    fullWidth
                    margin="dense"
                    required
                    value={message}
                    id="iris-message-contact-message"
                    label="Message"
                    multiline
                    rows={8}
                    onChange={updateMessage}
                />
                <Grid container>
                    <Grid item xs={11}></Grid>
                    <Grid item xs={1}>
                        <Typography variant="body2" gutterBottom sx={{textAlign: 'right', marginRight: '5px'}}>
                            {charsLeft}
                        </Typography>
                    </Grid>
                </Grid>
                <ReCaptchaV2
                    sitekey={'6LeQptImAAAAAGGX_0waRFZl6CQG3VGWBxbwQweF'}
                    onChange={handleTokenChange}
                    onExpired={handleTokenExpire}
                    style={{marginBottom: '20px'}}
                />
                {showSuccess ?
                    <Alert severity="success" sx={{marginBottom: '20px'}}>
                        Message Submitted! We will be in touch shortly.
                    </Alert>
                : null}
                {showAlert ?
                    <Alert severity="error" sx={{marginBottom: '20px'}}>
                        Oh no! There was a problem submitting your message. Please try again.
                    </Alert>
                : null}
                {captchaToken ?
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={submitted || !validEmail || !message?.length || !firstName?.length || !lastName?.length}
                        sx={{marginBottom: '40px'}}
                    >
                        Submit
                    </Button>
                : null}
            </Grid>
            <Grid item xs={1} md={2} lg={3} xl={4}></Grid>
        </Grid>
    )
}