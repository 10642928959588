import IconButton from '@mui/material/IconButton'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from "@fortawesome/free-solid-svg-icons"

type Props = {
    onClickCallback: Function
}

const ModalExitButton:React.FC<Props> = ({
    onClickCallback
}) => {
    return (
        <IconButton
            aria-label="exit"
            sx={{
                backgroundColor: '#f2f2f2',
                paddingY: '6px',
                paddingX: '9px'
            }}
            onClick={() => onClickCallback()}
        >
            <FontAwesomeIcon icon={faXmark} fontSize="24"/>
        </IconButton>
    )
}

export default ModalExitButton