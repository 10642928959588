import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import FAQ from '../components/FAQ'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

export default function FAQs() {
    return (
        <Grid container spacing={1}>
            <Grid item xs={1} md={2} lg={3}></Grid>
                <Grid item xs={10} md={8} lg={6}>
                    <Typography variant="h3" gutterBottom color={"#193959"} sx={{textAlign: 'center'}}>
                        <b>FAQs</b>
                    </Typography>
                    <Stack direction="row" spacing={7} sx={{marginY: '40px', justifyContent: 'center'}}>
                        <img
                            src={require('../media/SquirrelFAQ.png')}
                            width='250px'
                            alt='Squirrel under a radio tower with a question mark over his head'
                        />
                    </Stack>
                    <FAQ 
                        question='Wait, what is Iris?'
                        answer="Iris is a way to send personal messages into the universe using radio waves, which last forever. Iris Message is the opposite of social media.
                        Your message will remain completely anonymous, will not be received by anyone (on Earth), and will not get any likes or comments. If you
                        want to tell your friends, great! If not, even better!"
                    />
                    <FAQ 
                        question='How does Iris work?'
                        answer='Your message will be sent using our radio transmitter in Saint Paul, Minnesota.
                        The radio waves containing your message will leave the Earth in a split second and travel into space.'
                    />
                    <FAQ 
                        question='What kind of messages can I send?'
                        answer='Anything! As long as it is not hateful or in violation of US laws. It can be as profound or mundane as you want.
                        It could be a message about a family member. Or a message about a pet. Or maybe you are just really mad at your boss and
                        want the universe to know about it for the rest of eternity.'
                    />
                    <FAQ 
                        question='Do the messages really last forever?!'
                        answer="Yes! The radio waves that we use to send your message pass through the Earth's atmosphere and travel through the universe forever.
                        Even though they will become faint over time as they travel, they will nonetheless exist forever. It's like giving the universe
                        a small, permanent tattoo with your message that will exist long after we are gone."
                    />
                    <FAQ 
                        question='Will anyone read my message?'
                        answer='No. The message sending process is completely automated and no one will read your message.
                        Your message does not remain in our system once it has sent.'
                    />
                    <FAQ 
                        question='Why is it called Iris?'
                        answer='In Greek mythology, Iris is the messenger goddess who delivers messages to and from the gods.'
                    />
                </Grid>
            <Grid item xs={1} md={2} lg={3}></Grid>
        </Grid>
    )
}