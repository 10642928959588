import { useState, Fragment }from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { StatsInputType } from '../types/StatsInputType'
import { generateSourceId } from '../utils/Helpers'
import { getStats } from '../clients/TransmissionClient'
import MonthlyBarChart from '../components/MonthlyBarChart'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

export default function StatsMessages() {
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [disabled, setDisabled] = useState<boolean>(false)
    const [canView, setCanView] = useState<boolean>(false)
    const [showAlert, setShowAlert] = useState<boolean>(false)

    const [sentToday, setSentToday] = useState<number>()
    const [sentThisMonth, setSentThisMonth] = useState<number>()
    const [totalSent, setTotalSent] = useState<number>()
    const [sentByMonth, setSentByMonth] = useState<Array<any>>()

    const updateUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value)
    }

    const updatePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }

    const handleGetStats = () => {
        setDisabled(true)
        const statsData: StatsInputType = {
            "sourceId": generateSourceId(),
            "username": username,
            "password": password
        }

        getStats(statsData).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setCanView(true)
                setShowAlert(false)
                setSentToday(response.data.sentToday)
                setSentThisMonth(response.data.sentThisMonth)
                setTotalSent(response.data.totalSent)
                setSentByMonth(response.data.sentByMonth)
            } else {
                setShowAlert(true)
                setCanView(false)
            }
        })
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sx={{textAlign: "center"}}>
                <TextField
                    fullWidth
                    margin="dense"
                    type="text"
                    label="Username"
                    onChange={updateUsername}
                    sx={{width: "350px"}}
                />
            </Grid>
            <Grid item xs={12} sx={{textAlign: "center"}}>
                <TextField
                    fullWidth
                    margin="dense"
                    type="password"
                    label="Password"
                    onChange={updatePassword}
                    sx={{width: "350px"}}
                />
                <Grid item xs={12} sx={{textAlign: "center"}}>
                <Button
                    variant="contained"
                    disabled={disabled}
                    onClick={handleGetStats}
                    sx={{marginBottom: '30px'}}
                >
                    Get Stats
                </Button>
            </Grid>
            <Grid item xs={12} sx={{textAlign: "center"}}>
                {showAlert ? <Alert severity="error" sx={{justifyContent: "center"}}>Incorrect password</Alert> : null}
            </Grid>
            </Grid>
            {canView ?
                <Fragment>
                    <Grid item xs={12} md={4} sx={{textAlign: "center"}}>
                        <Typography variant="h3" gutterBottom color={"#193959"} sx={{textAlign: 'center'}}>
                            Messages Sent Today
                        </Typography>
                        <Typography variant="h1" gutterBottom color={"#193959"} sx={{textAlign: 'center'}}>
                            <b>{sentToday}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{textAlign: "center"}}>
                        <Typography variant="h3" gutterBottom color={"#193959"} sx={{textAlign: 'center'}}>
                            Messages Sent this Month
                        </Typography>
                        <Typography variant="h1" gutterBottom color={"#193959"} sx={{textAlign: 'center'}}>
                            <b>{sentThisMonth}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{textAlign: "center"}}>
                        <Typography variant="h3" gutterBottom color={"#193959"} sx={{textAlign: 'center'}}>
                            Total Messages Sent
                        </Typography>
                        <Typography variant="h1" gutterBottom color={"#193959"} sx={{textAlign: 'center'}}>
                            <b>{totalSent}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: "center", justifyContent: 'center'}}>
                        <Typography variant="h3" gutterBottom color={"#193959"} sx={{textAlign: 'center'}}>
                            Messages Sent per Month
                        </Typography>
                        <MonthlyBarChart data={sentByMonth}/>
                    </Grid>
                </Fragment>
            : null
            }
        </Grid>
    )
}