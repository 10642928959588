import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts"

type Props = {
    data?: Array<any>
}

const MonthlyBarChart:React.FC<Props> = ({data}) => {
    return (
        data?.length ?
            <BarChart
                width={900}
                height={400}
                data={data}
                style={{display: 'inline-block'}}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#507F54" />
            </BarChart>
        : <div>No data</div>
    )
}

export default MonthlyBarChart
