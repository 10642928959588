import { useState } from 'react'

import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'

import ModalExitButton from './ModalExitButton'

import { OrderType } from '../types/OrderType'
import { postOrderBalanced } from '../clients/TransmissionClient'
import { generateSourceId } from '../utils/Helpers'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import './Modal.css'

type Props = {
    modalOpen: boolean
    setModalOpen: Function
    setTransmissionModalOpen: Function
    setTransmissionErrorModalOpen: Function
    message: string
    messageType: string
    charity?: string
    email: string,
    setSuccessMessage: Function,
    setOrderId: Function
}

const EnterGiftCodeModal:React.FC<Props> = ({
    modalOpen,
    setModalOpen,
    setTransmissionModalOpen,
    setTransmissionErrorModalOpen,
    message,
    messageType,
    charity,
    email,
    setSuccessMessage,
    setOrderId
}) => {
    const [validGiftCode, setValidGiftCode] = useState<boolean>(false)
    const [giftCode, setGiftCode] = useState<string>('')
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [isVerifying, setIsVerifying] = useState<boolean>(false)

    const handleClose = () => {
        setGiftCode('')
        setShowAlert(false)
        setIsVerifying(false)
        setValidGiftCode(false)
        setModalOpen(false)
    }

    const updateGiftCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        const filteredCode = event.target.value
        .trim()
        .substring(0, 15)
        .replace(/\W/g, '')

        setGiftCode(filteredCode)

        if(filteredCode.length === 15) {
            setValidGiftCode(true)
        } else if (validGiftCode) {
            setValidGiftCode(false)
        }
    }

    const handleSubmitGiftMessage = () => {
        //If gift code is valid, otherwise display error and stay on modal and disable continue for 2 sec
        setIsVerifying(true)

        const cost = messageType === 'HAND_WRITTEN' ? 2.99 : 1.99
        const data: OrderType = {
            giftCode: giftCode,
            message: message,
            messageType: messageType,
            charity: charity,
            cost: cost,
            email: email,
            sourceId: generateSourceId()
        }

        postOrderBalanced(data).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setOrderId(response.data?.orderId)
                if (response.data?.message) {
                    setSuccessMessage(response.data.message)
                }
                handleClose()
                setTransmissionModalOpen(true)
            } else if (response.data === "INVALID_GIFT_CODE"){
                setShowAlert(true)
                setTimeout(() => {
                    setIsVerifying(false)
                }, 2000)
            } else {
                setTransmissionErrorModalOpen(true)
            }
        })
    }
    
    return (
        <Dialog
            fullWidth={true}
            maxWidth='sm'
            open={modalOpen}
            onClose={handleClose}
        >
            <DialogContent>
                <Grid container spacing={1} sx={{height: '220px', paddingX: '0px'}}>
                    <Grid item xs={12} md={12}>
                        <ModalExitButton onClickCallback={handleClose} />
                        <TextField
                            fullWidth
                            helperText={validGiftCode ? null : "*Please enter a valid gift code"}
                            margin="dense"
                            id="iris-gift-code"
                            label="Gift Code"
                            rows={1}
                            value={giftCode}
                            onChange={updateGiftCode}
                            sx={{marginBottom: "15px", marginTop: '15px'}}
                        />
                        {isVerifying ?
                            <Typography variant="body1" gutterBottom sx={{textAlign: 'center'}}>
                                Verifying gift code
                            </Typography> :
                            null
                        }
                        {showAlert ? <Alert severity="error" sx={{marginBottom: '10px'}}>Gift code is not valid. Please try again.</Alert> : null}
                        <Button
                            variant="contained"
                            onClick={handleSubmitGiftMessage}
                            disabled={isVerifying || !validGiftCode}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default EnterGiftCodeModal
