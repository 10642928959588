import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export default function ErrorPage() {
    return (
        <Grid container spacing={1}>
            <Grid item xs={1} md={3}></Grid>
            <Grid item xs={10} md={6} sx={{textAlign: "center"}}>
                <Typography variant="h2" gutterBottom color={"#193959"} sx={{textAlign: 'center'}}>
                    <b>Oops!</b>
                </Typography>
                <Typography variant="h5" gutterBottom sx={{textAlign: 'center', marginBottom: '40px'}}>
                    Sorry, the page you're looking for is not available.
                </Typography>
                <img
                    src={require('../media/SquirrelError.jpg')}
                    width='60%'
                    alt='Squirrel looking under a wooden deck'
                />
            </Grid>
            <Grid item xs={1} md={3}></Grid>
        </Grid>
    )
}
